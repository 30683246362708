import React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Splode from '../images/splode-green.svg'

const NotFoundPage = () => (
  <Layout className="not-found">
    <SEO title="404: Not found" />
    <div className="container">
      <img className="splode" src={Splode} alt="splode" />
      <div className="content-wrapper">
        <h2>404: There's nothing to see here</h2>
        <Link
          to="/"
          className="btn-purple">
          Go home
        </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
